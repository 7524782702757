import React from 'react'
import { Link } from 'gatsby'
import Helmet from 'react-helmet'
import Layout from '../components/layout'
import BannerLanding from '../components/BannerLanding'

import pic_mariage_1 from "../assets/images/pic_mariage_8.jpg";
import pic_mariage_3 from "../assets/images/pic_mariage_3.jpg";
import pic_mariage_6 from "../assets/images/pic_mariage_6.jpg";
import Biographie from "../components/Biographie";


const Landing = (props) => (
    <Layout>
        <Helmet>
            <title>Vincent DUCOS photographe mariage</title>
            <meta name="description" content="Photos mariage Poitiers" />
        </Helmet>

        <BannerLanding />

        <div id="main">
            <section id="one">
                <div className="inner">
                    <header className="major">
                        <h2>Mariages</h2>
                    </header>

                </div>
            </section>
            <section id="two" className="spotlights">
                <section>
                    <span className="image "><img src={pic_mariage_1} alt="" /></span>
                    <div className="content">
                        <div className="inner">
                            <p>
                                Je photographie les mariages depuis plus 10 ans pour sublimer les émotions et les petits instants magiques de ce moment unique, tout en restituant une image authentique de cette belle journée.
                                <br/><br/>J'accompagne les mariés au cours de la plus belle journée de leur vie. Je réalise des reportages complets de votre journée de mariage, des préparatifs à la soirée en passant par la cérémonie (laïque ou religieuse), le vin d'honneur, le repas.
                            </p>
                        </div>
                    </div>
                </section>
            </section>
            <section id="one">
                <div className="inner">
                    <div className="box alt">
                        <div className="grid-wrapper">
                            <div className="col-4"><span className="image fit"><img src={pic_mariage_6} alt="" /></span></div>
                            <div className="col-4"><span className="image fit"><img src={pic_mariage_1} alt="" /></span></div>
                            <div className="col-4"><span className="image fit"><img src={pic_mariage_3} alt="" /></span></div>
                        </div>
                    </div>
                </div>
            </section>
            <Biographie />
        </div>

    </Layout>
)

export default Landing